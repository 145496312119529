<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="用户名" class="typeInput">
          <el-input
            v-model="searchForm.userNameSearch"
            placeholder="用户名"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="注册手机号" class="typeInput">
          <el-input
            v-model="searchForm.registerMobileSearch"
            placeholder="注册手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="是否团餐用户" class="typeInput">
          <el-select v-model="searchForm.isGroupSearch" filterable clearable>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="团餐绑定手机号" class="typeInput">
          <el-input
            v-model="searchForm.mobileSearch"
            placeholder="团餐绑定手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="用户ID" class="typeInput">
          <el-input
            v-model="searchForm.uuid"
            placeholder="用户ID"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="openReport()"
            v-if="isAuth('tc:userAccount:openReport')"
            >导出报表</el-button
          >
          <el-button
            type="primary"
            v-if="isAuth('mall:user:recharge')"
            @click="$dialog('userRecharge')"
            >余额充值</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <h4>费用总览</h4>
    <div class="listBtn">
      <span>
        余额账户金额总计:<b>{{ this.totalBalance }}</b>
      </span>
    </div>

    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="用户名"
      >
      </el-table-column>
      <el-table-column
        prop="uuid"
        header-align="center"
        align="center"
        label="用户ID"
      >
      </el-table-column>
      <el-table-column
        prop="registerMobile"
        header-align="center"
        align="center"
        label="注册手机号"
      >
      </el-table-column>
      <el-table-column
        prop="isGroupText"
        header-align="center"
        align="center"
        label="是否团餐用户"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="团餐绑定手机号"
      >
      </el-table-column>
      <el-table-column
        prop="giftBalance"
        header-align="center"
        align="center"
        label="赠送余额"
      >
      </el-table-column>
      <el-table-column
        prop="subsidyBalance"
        header-align="center"
        align="center"
        label="补助余额"
      >
      </el-table-column>
      <el-table-column
        prop="rechargeBalance"
        header-align="center"
        align="center"
        label="充值余额"
      >
      </el-table-column>
      <el-table-column
        prop="balance"
        header-align="center"
        align="center"
        label="余额账户金额"
      >
      </el-table-column>
      <el-table-column
        prop="registerTime"
        header-align="center"
        align="center"
        label="注册时间"
      >
      </el-table-column>
      <el-table-column
        prop="lastLoginTime"
        header-align="center"
        align="center"
        label="最后登录时间"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <user-recharge v-if="userRechargeVisible" ref="userRecharge" />
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import UserRecharge from './user-recharge';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      totalPage: 0,
      userRechargeVisible: false,
      searchForm: {
        userNameSearch: '',
        registerMobileSearch: '',
        isGroupSearch: '',
        mobileSearch: '',
        uuid: '',
      },
      totalBalance: '',

      groupList: [
        {
          id: 0,
          name: '否',
        },
        {
          id: 1,
          name: '是',
        },
      ],
    };
  },
  components: { UserRecharge },
  activated() {
    this.getDataList(1);
  },
  created() {},
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$getDataList({
        url: '/report/mallUser/userAccount',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.searchForm,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            this.totalBalance = data.total;
            this.totalPage = data.page.totalCount;
          } else {
            this.dataList = [];
          }
        },
      });
    },

    openReport() {
      window.open(
        `${process.env.VUE_APP_TABLE_BASE_URL}
      mall_user.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &user_name_search=${this.searchForm.userNameSearch}&register_mobile_search=${this.searchForm.registerMobileSearch}
      &is_group_search=${this.searchForm.isGroupSearch}&mobile_search=${this.searchForm.mobileSearch}&search_uuid=${this.searchForm.uuid}
      `.replaceAll(' ', ''),
      );
    },
    unique(arr) {
      // 根据唯一标识orderId来对数组进行过滤
      const res = new Map(); //定义常量 res,值为一个Map对象实例
      //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
    },
  },
};
</script>
