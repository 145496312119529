<template>
  <el-dialog
    title="余额充值"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <div class="tablePart">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="80px"
      >
        <div class="tablePart">
          <el-form-item label="用户id" prop="uuid">
            <el-input v-model="dataForm.uuid" />
          </el-form-item>
          <el-form-item label="充值金额" prop="rechargeMoney">
            <el-input-number
              v-model="dataForm.rechargeMoney"
              :min="0"
              :step="0.01"
              :precision="2"
            />
          </el-form-item>
          <el-form-item label="赠送金额" prop="giftAmount">
            <el-input-number
              v-model="dataForm.giftAmount"
              :min="0"
              :step="0.01"
              :precision="2"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visible: false,
      dataForm: {
        uuid: '',
        rechargeMoney: 0.01,
        giftAmount: 0
      },
      dataList: [],
      dataRule: {
        rechargeMoney: [
          { required: true, message: '充值金额不能为空', trigger: 'blur' },
        ],
        uuid: [{ required: true, message: '用户id不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    init() {
      // this.dataForm.uuid = '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/user/recharge`,
            method: 'post',
            data: {
              rechargeMoney: this.dataForm.rechargeMoney,
              giftAmount: this.dataForm.giftAmount,
              uuid: this.dataForm.uuid,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },
  },
};
</script>
